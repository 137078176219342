export const PERMANENT_FEATURES = Object.freeze({
	analyticsEventLogger: 'analyticsEventLogger',
	brazeSDK: 'brazeSDK',
	cypressMirageDebugging: 'cypressMirageDebugging',
	datadogRum: 'datadogRum',
	forcePromoBanner: 'forcePromoBanner',
	hotjar: 'hotjar',
	marketplaceInternalTestingFilters: 'marketplaceInternalTestingFilters',
	mirageServer: 'mirageServer',
	mirageServerSoftLaunch: 'mirageServerSoftLaunch',
	paymentsSkipFilterPaymentOptions: 'paymentsSkipFilterPaymentOptions',
	reactQueryDevtools: 'reactQueryDevtools',
	saleTimeController: 'saleTimeController',
	searchService: 'searchService',
	serverScenarioSelector: 'serverScenarioSelector',

	snowplowMiniRedirect: 'snowplowMiniRedirect',
	testEnv: 'testEnv',
	vehicleStateExplorer: 'vehicleStateExplorer',
	webVitalsLogging: 'webVitalsLogging',
});

export const TEMPORARY_FEATURES = Object.freeze({
	accountReporting: 'accountReporting',
	accountSettings: 'accountSettings',
	additionalMileage: 'additionalMileage',
	additionalVehicleDetails: 'additionalVehicleDetails',
	auctionResultsModal: 'auctionResultsModal',
	bidCountsSort: 'bidCountsSort',
	// Backend toggle. Use to switch all bidding related endpoints to use prepareBiddingClientWithAuth
	biddingMigrationBidsEndpoint: 'biddingMigrationBidsEndpoint',

	biddingMigrationCancelBidEndpoint: 'biddingMigrationCancelBidEndpoint',
	binDiscountVisibility: 'binDiscountVisibility',
	binEndingSoonSort: 'binEndingSoonSort',
	binExpiryVisibility: 'binExpiryVisibility',
	dealerLiveChatChatBot: 'dealerLiveChatChatBot',
	// Temporary feature flag to be removed after problem with NODE_ENV being passed to cypress is resolved
	disablePromoBanner: 'disablePromoBanner',

	// Dealer account base flag, managed from BE.
	documentCaptureV2: 'documentCaptureV2',

	enhancedServiceHistory: 'enhancedServiceHistory',

	estimatedDeliveryCosts: 'estimatedDeliveryCosts',
	expandedPreviousOwners: 'expandedPreviousOwners',
	forceRefreshOnOutOfSyncBuild: 'forceRefreshOnOutOfSyncBuild',
	inspectionAppUrl: 'inspectionAppUrl',

	// FF for Generic KYC features. Initially within account-settings
	kyc: 'kyc',

	liveSaleDiscountVisibility: 'liveSaleDiscountVisibility',

	// Flag to control if we send to DD zod schema difference in VDP
	logVdpSchemaDifference: 'logVdpSchemaDifference',

	marketplaceDynamicCsv: 'marketplaceDynamicCsv',

	// Motorway Move callback functionality
	motorwayMoveCallback: 'motorwayMoveCallback',

	multiSiteDealer: 'multiSiteDealer',
	negativeEquityV01: 'negativeEquityV01',
	nextGearPaymentOptions: 'nextGearPaymentOptions',
	// Dealer account base flag, managed from BE.
	paymentsFeeCollection: 'paymentsFeeCollection',
	// Dealer account base flag, managed from BE.
	paymentsHideSellerBankDetails: 'paymentsHideSellerBankDetails',
	// Dealer account base flag, managed from BE.
	paymentsKYC: 'paymentsKYC',
	paymentsMVP: 'paymentsMVP',
	paymentsOTP: 'paymentsOTP',
	penTestingBidding: 'penTestingBidding',
	pricingUpdates: 'pricingUpdates',
	// Backend toggle. Use for validation.
	relistDetailsSection: 'relistDetailsSection',
	searchService: 'searchService',
	sellerTypeFilter: 'sellerTypeFilter',
	similarVehiclesMakeModel: 'similarVehiclesMakeModel',
	similarVehiclesV2: 'similarVehiclesV2',
	socketErrorToast: 'socketErrorToast',
	staticGoogleMap: 'staticGoogleMap',
	surfacingBidInformation: 'surfacingBidInformation',
	tradeDealerInfo: 'tradeDealerInfo',
	tradeExperience: 'tradeExperience',
	tradeNavigation: 'tradeNavigation',
	tradeVehicleBadge: 'tradeVehicleBadge',
	tradeVehicleBadgeAccount: 'tradeVehicleBadgeAccount',
	transportExperience: 'transportExperience',
	walletWithdrawal: 'withdraw_funds',
});

export const FEATURES = Object.freeze({ ...PERMANENT_FEATURES, ...TEMPORARY_FEATURES });

export const USER_SESSION_BASED_FEATURES = new Set([
	FEATURES.kyc,
	FEATURES.paymentsFeeCollection,
	FEATURES.paymentsHideSellerBankDetails,
	FEATURES.paymentsKYC,
	FEATURES.paymentsMVP,
	FEATURES.paymentsOTP,
	FEATURES.paymentsSkipFilterPaymentOptions,
]);

export const FEATURES_ARRAY = Object.keys(FEATURES);

export const FEATURES_ENABLED_COOKIE_NAME = 'mwDisplayFeaturesManager';

export const MIRAGE_SCENARIO = 'mirageScenario';

export const ALL_FEATURES_RELATED_COOKIES = [].concat(FEATURES_ENABLED_COOKIE_NAME, FEATURES_ARRAY);

export const COMMA_SEPARATED_IDS_EXPRESSION = /^([1-9]\d*)(,\d+)*$/g;

export const COMMA_SEPARATED_NEGATIVE_IDS_EXPRESSION = /^(![1-9]\d*)(,!\d+)*$/g;

export const FEATURE_SETS = [
	{
		features: PERMANENT_FEATURES,
		title: 'Permanent features',
	},
	{
		features: TEMPORARY_FEATURES,
		title: 'Temporary features',
	},
];
